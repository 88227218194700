<template>
  <div
    :checked="state"
    :class="className"
    :disabled="disabled"
    :id="id"
    :name="name"
    :required="required"
    @click.self="onChange"
    class="checkbox-component"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "input",
  },
  props: {
    id: {
      type: String,
      default: function () {
        return "checkbox-id-" + this._uid;
      },
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    modelValue: {
      default: undefined,
    },
    className: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    model: {},
  },
  computed: {
    state() {
      var vm = this;
      if (this.modelValue === undefined) {
        return this.checked;
      }
      if (Array.isArray(this.modelValue)) {
        if (Array.isArray(vm.value)) {
          var intersect = this.modelValue.filter(function (v) {
            return vm.value.indexOf(v) > -1;
          });

          return intersect.length > 0;
        } else return this.modelValue.indexOf(this.value) > -1;
      }
      return !!this.modelValue;
    },
  },
  methods: {
    onChange() {
      this.toggle();
    },
    toggle() {
      let value;
      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);
        if (this.state) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else {
        value = !this.state;
      }
      this.$emit("input", value);
    },
  },
  watch: {
    checked(newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    },
  },
  mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  },
};
</script>
<style lang="scss">
.checkbox-component {
  cursor: pointer;
  justify-content: space-around;
  display: flex;
  align-items: center;
  border: 1px solid #7c7c84;
  padding: 1px 16px;
  font-size: 12px;
  line-height: 17px;

  color: #7c7c84;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px 15px 5px;
  border-radius: 4px;

  &[checked="checked"],
  &:hover {
    color: #cdbbff;
    border: 1px solid #cdbbff;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
</style>
