<template>
  <div>
    <!-- <section-h1 :word="'Home'" /> -->
    <div class="mt-5 row">
      <div v-for="(news, index) in newsList" :key="index" class="col-12 col-md-6 col-lg-6 col-xl-4 mb-5">
        <newsCard :number="index" :news="news"></newsCard>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllNews } from "@/axios/app.js";
import newsCard from "../components/newsCard.vue";

export default {
  components: {
    newsCard,
  },
  data() {
    return {
      newsList: null,
    };
  },
  name: "Home",
  created() {
    getAllNews().then((r) => {
      this.newsList = r.data.data;
    });
  },
  methods: {
    recreatedNews(val) {
      let ans = [];
      val.forEach((e) => {
        e.news.forEach((news) => {
          ans.push(news);
        });
      });
      return ans;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-right: 19px;
  margin-left: 19px;
}
</style>
