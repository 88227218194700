<template>
  <div>
    <div class="news-block">
      <div>
        <div class="category">{{ news.name }}</div>
      </div>
      <div class="d-flex align-items-center media-icon-box">
        <CheckBox
          class="cursor-pointer icon-checkbox"
          v-for="media in news.mediaArray"
          :key="media.id"
          :value="media.mediaName"
          v-model="iconTypes"
        >
          <img
            :src="readMedIcon(media.mediaName)"
            :class="media.show ? 'icon-show' : ''"
            class="icon"
          />
        </CheckBox>
      </div>
    </div>
    <div class="blur"></div>
    <div :id="`media-name-box${number}`" class="media-name-box">
      <CheckBox
        class="cursor-pointer checkbox"
        v-for="media in news.mediaArray"
        :key="media.id"
        :value="media.mediaName"
        v-model="keyTypes"
      >
        {{ media.mediaName }}
      </CheckBox>
    </div>

    <div class="news-info-box">
      <router-link
        :to="`/about/${news.id}`"
        v-for="(news, index) in newsList"
        :key="index"
        class="news-info"
      >
        <router-link
          :to="`/about/${news.id}`"
          :class="news.show ? '' : 'news-title-dark'"
          class="cursor-pointer news-title-light"
        >
          {{ news.title }}
        </router-link>
      </router-link>
    </div>
  </div>
</template>
<script>
import CheckBox from "@/components/CheckBox.vue";

export default {
  components: {
    CheckBox,
  },
  data() {
    return {
      onErr: false,
      newsList: null,
      keyTypes: [],
      iconTypes: [],
    };
  },
  props: {
    news: [],
    number: Number,
  },
  watch: {
    keyTypes() {
      this.filtersTagClick();
    },
    iconTypes() {
      this.fiterIconClick();
    },
  },
  created() {
    this.recreatedNews(this.myNews);
  },
  mounted() {
    this.mediaNameScroll();
  },
  computed: {
    myNews() {
      return this.news;
    },
  },
  methods: {
    readMedIcon(imageName){
    let ans = null;
      try{
        ans = require(`@/assets/mediaIcon/${imageName}.svg`);
        return ans;
      }catch{
        ans = require(`@/assets/defaultIcon.svg`);
        return ans
      }
    },
    replaceMideaName(val) {
      let ans = val.replace(/\s+/g, "_");
      console.log(ans);
      return ans;
    },
    mediaNameScroll() {
      const ele = document.getElementById(`media-name-box${this.number}`);
      ele.style.cursor = "grab";

      let pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseDownHandler = function (e) {
        ele.style.cursor = "grabbing";
        ele.style.userSelect = "none";

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = function (e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = function () {
        ele.style.cursor = "grab";
        ele.style.removeProperty("user-select");

        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      // Attach the handler
      ele.addEventListener("mousedown", mouseDownHandler);
    },
    recreatedNews(val) {
      let ans = [];
      val.mediaArray.forEach((e) => {
        e.news.forEach((news) => {
          news.mediaName = e.mediaName;
          news.show = true;
          ans.push(news);
        });
      });
      // this.newsList = ans.sort(() => Math.random() - 0.5);
      this.newsList = ans.sort((first,second)=>second.id - first.id);
    },
    fiterIconClick() {
      this.keyTypes = this.iconTypes;
      this.fiterNewsByFilterType();
    },
    filtersTagClick() {
      let keyTypes = this.keyTypes;
      this.iconTypes = keyTypes;
      this.fiterNewsByFilterType();
    },
    fiterNewsByFilterType() {
      this.myNews.mediaArray.forEach((item) => {
        var isInArray = this.iconTypes.includes(item.mediaName);
        if (isInArray) {
          item.show = true;
        } else {
          item.show = false;
        }
      });

      this.newsList.forEach((e) => {
        var isInArray = this.keyTypes.includes(e.mediaName);
        if (isInArray) {
          e.show = true;
        } else {
          e.show = false;
        }
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.news-block {
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.icon-checkbox {
  border: none;
  padding: 0;
  margin: 0;
  &:hover {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
  .icon {
    width: 30px;
    height: 30px;
    pointer-events: none;

    &.icon-show {
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
  }
}

.category {
  width: 180px;
  height: 50px;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: baseline;
  text-align: left;
  color: #dfdfdf;
}
.media-icon-box{
  width: 150px;
  overflow: auto;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.233);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}
.media-name-box {
  display: flex;
  padding-bottom: 0;
  margin: 10px 0 0 0;
  width: 100%;
  cursor: grab;
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.233);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.news-info-box {
  margin: 6px 0px;
  height: 570px;
  border-radius: 15px;
  overflow-y: auto;

  scrollbar-width: 0px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.233);
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .news-info {
    display: flex;
    flex-direction: row;
    padding: 12px 10px;
    background: #555555;
    text-decoration: none;

    font-size: 13px;
    line-height: 18.2px;

    align-items: center;

    color: #dfdfdf;

    flex: none;
    order: 0;
    flex-grow: 1;
    text-align: left;

    a {
      color: #dfdfdf;
      text-decoration: none;
    }

    &:hover {
      padding: 11px 9px;
      border: 1px solid #aaffe0;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px 2px rgba(170, 255, 255, 0.5);
      a {
        color: #dfdfdf;
        text-decoration: none;
      }
    }

    &:nth-child(odd) {
      background-color: #474747;
    }

    &:first-child {
      border-radius: 15px 15px 0 0;
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
    }

    .news-title-light {
      color: #dfdfdf;
    }

    .news-title-dark {
      color: #7c7c84;
    }
  }
}

.blur {
  display: flex;
  justify-content: center;
  margin-left: auto;
  position: relative;
  height: 23px;
  width: 50%;
  top: 32px;
  background-image: -webkit-linear-gradient(
    right,
    #373737d9,
    rgba(40, 40, 40, 0),
    transparent
 );
  pointer-events: none;
}
</style>
