import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import "@fortawesome/fontawesome-free/css/all.min.css";
import VueLazyload from "vue-lazyload";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

const loadimage = require("./assets/error.png");
const errorimage = require("./assets/daceShiba.gif");

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(Vuex);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
