<template>
  <b-dropdown id="header-dropdown" text="Trending: 1.BitCoin..." class="">
    <div class="dropdown-title">KEYWORD RANKING</div>
    <div class="pl-2 tabs-header">
      <button
        class="button-tabs"
        v-for="tab in tabs"
        :key="tab"
        :class="['bottomLine', { active: currentTab === tab }]"
        @click="currentTab = tab"
      >
        {{ tab }}
      </button>
    </div>

    <hot-key-card
      v-if="currentTab === 'by Title'"
      v-on:changeTitleTime="changeTitleTime"
      :rankList="rankTitleList"
      :type="'Title'"
    ></hot-key-card>
    <hot-key-card
      v-if="currentTab === 'by Content'"
      v-on:changeContentTime="changeContentTime"
      :rankList="rankContentList"
      :type="'Content'"
    ></hot-key-card>
  </b-dropdown>
</template>

<script>
import {
  getTitleRank,
  getcontentRank,
  getTitleRankByTime,
  getContentRankByTime,
} from "@/axios/app.js";
import * as DateTime from "@/utlis/DateTime";
import hotKeyCard from "./hotKeyCard.vue";
export default {
  data() {
    return {
      rankTitleList: null,
      rankContentList: null,
      arrow: false,
      dropDownText: "24H",
      loadingShow: "false",
      currentTab: "by Title",
      tabs: ["by Title", "by Content"],
    };
  },
  components: {
    hotKeyCard,
  },
  created() {},
  mounted() {
    getTitleRank().then((r) => {
      this.rankTitleList = r.data.data;
    });

    getcontentRank().then((r) => {
      this.rankContentList = r.data.data;
    });
  },
  methods: {
    changeTime(val, unit) {
      this.loadingShow = true;
      this.arrow = false;
      var dict = { h: "H", d: "D", M: "M" };
      var dropDownText = val + dict[unit];
      this.dropDownText = dropDownText;

      var obj = {
        value: val,
        unit: unit,
      };
      if (this.type == "Title") {
        this.$emit("changeTitleTime", obj);
      } else if (this.type == "Content") {
        this.$emit("changeContentTime", obj);
      }
    },
    changeTitleTime(obj) {
      let eTime = DateTime.toUnixTime(new Date().getTime());
      let sTime = DateTime.toUnixTime(
        new Date(DateTime.add(new Date(), -obj.value, obj.unit))
      );
      getTitleRankByTime(sTime, eTime).then((r) => {
        this.rankTitleList = r.data.data;
      });
    },
    changeContentTime(obj) {
      let eTime = DateTime.toUnixTime(new Date().getTime());
      let sTime = DateTime.toUnixTime(
        new Date(DateTime.add(new Date(), -obj.value, obj.unit))
      );
      getContentRankByTime(sTime, eTime).then((r) => {
        this.rankContentList = r.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.tabs-header {
  position: absolute;
  width: 160px;
  z-index: 99;
  top: 43px;
}
.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  color: #dfdfdf;
  background: #2f2f2f;
  border-radius: 3px;
  padding: 2px 8px;
  cursor: pointer;
}

.dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top-color: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
}

.dd-button:hover {
  background: #2f2f2f;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  list-style-type: none;
  width: 60px;
  background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  color: #000000;
  z-index: 99;
  text-align: left;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li {
  color: #dfdfdf;
  padding: 10px 7px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background: #313131;
  color: #dfdfdf;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.dropdown-title {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 4px;
  margin-bottom: 10px;
}
// 手機版 hotketwords tabs 部分css
.button-tabs {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  background: transparent;
  color: #ffffff;
  border-color: transparent;
}
// .bottomLine {
//   width: 50%;
//   height: 3px;
//   background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
//   margin-left: 15px;
// }
.button-tabs.bottomLine.active {
  background: transparent;
  -webkit-filter: unset;
  z-index: -1;
  border-bottom: solid 2px;
  border-color: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
}
</style>