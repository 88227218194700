/* eslint-disable prettier/prettier */
//https://eugenesu0515.github.io/Blog/2018/06/25/Vue%E5%A5%97%E4%BB%B6%E4%BB%8B%E7%B4%B9%EF%BC%9Aaxios/

import axios from "axios";

// const baseUrl = "http://35.201.131.54:3001";
const baseUrl = "https://api.fud.city";

const backend = axios.create({
    baseURL: baseUrl,
});
export const getAllNews = () => backend.get(`/news`);
export const getTitleRank = () => backend.get(`/tag/rank?limit=15&sortAt=title`);
export const getcontentRank = () => backend.get(`/tag/rank?limit=15&sortAt=content`);
export const getKeywordSearch = (pram) => backend.post(`/tag/find/`, pram);
export const getKeywordRelation = (pram) => backend.get(`/tag/find/relation/${pram}`);
export const getSelectMedia = () => backend.get(`/media`);
export const getTagSearch = (tagid, page) => backend.get(`/tag/${tagid}?page=${page}&pageSize=24`);


export const getTitleRankByTime = (start, end) => backend.get(`/tag/rank?sortAt=title&startTime=${start}&endTime=${end}`);
export const getContentRankByTime = (start, end) => backend.get(`/tag/rank?sortAt=content&startTime=${start}&endTime=${end}`);
export const getContentUsageByTime = (id, sortAt, start, end, interval) => backend.get(`/tag/${id}/usage?sortAt=${sortAt}&startTime=${start}&endTime=${end}&interval=${interval}`);
export const getTitleUsageByTime = (id, sortAt, start, end, interval) => backend.get(`/tag/${id}/usage?sortAt=${sortAt}&startTime=${start}&endTime=${end}&interval=${interval}`);

export const getNewsByID = (pram) => backend.get(`/news/${pram}`);

export const apiBaseUrl = baseUrl;


