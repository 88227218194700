/* eslint-disable prettier/prettier */
var moment = require("moment");
export function firstDayOfMonth(date) {
    if (date) return moment(date).startOf("month").format();
    return moment().startOf("month").format();
}
export function lastDayOfMonth(date) {
    var ans;
    if (date) ans = moment(date).endOf("month").format();
    else ans = moment().endOf("month").format();
    return ans;
}

export function toDate(date) {
    var ans = moment(date).toDate();
    return ans;
}

export function toDate05(date, format = "YYYY-MM-DD HH:mm:ss") {
    var temp = new Date(date);
    var minute = (temp.getMinutes() % 5) * -1;
    var ans = moment(date).add(minute, "m").format(format);
    return ans;
}
//Units are case insensitive, and support plural and short forms:
//year (years, y), month (months, M), date (dates, D), hour (hours, h), minute (minutes, m), second (seconds, s), millisecond (milliseconds, ms).
export function add(date, value, unit, format = "YYYY-MM-DD HH:mm:ss") {
    var ans = moment(date).add(value, unit).format(format);
    return ans;
}
export function lastTimeOfDay(date, format = "YYYY-MM-DD HH:mm:ss") {
    return moment(date).endOf("day").format(format);
}
export function firstTimeOfDay(date, format = "YYYY-MM-DD HH:mm:ss") {
    return moment(date).startOf("day").format(format);
}
export function format(value, format = "YYYY-MM-DD HH:mm:ss") {
    if (moment(value).isValid()) {
        var ans = moment(value).format(format);
        return ans;
    }
    return "";
}
export function diff(d1, d2, format = "days") {
    if (!d1 || !d2) return "";
    var a = moment(d1);
    var b = moment(d2);
    return b.diff(a, format, true);
}

export function lastDayOfLastMonth(d1) {
    if (!d1) d1 = moment();
    var date = moment(d1)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    return date;
}

export function firstDayOfLastMonth(d1) {
    if (!d1) d1 = moment();
    var date = moment(d1)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    return date;
}

export function toJavaScriptTime(val) {
    let ans = val * 1000;
    return ans;
}

export function toUnixTime(val) {
    let ans = Math.floor(val / 1000);
    return ans;
}
