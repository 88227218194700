import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    type: "",
    showbar: false,
    mediabar: false,
  },
  mutations: {
    changeType(state, type) {
      state.type = type;
    },
    changeShowBarState(state, showbar){
      state.showbar = showbar;
    },
    changeMediaBarState(state, mediabar){
      state.mediabar = mediabar;
    }
  },
});

export default store;