<template>
  <div>
    <div class="background" :class="{ phoneheader: searchPhone }">
      <router-link class="logo d-none d-md-flex" to="/">
        <div>LOGO</div>
      </router-link>
      <div class="search-box d-none d-md-flex col-7">
        <div class="search-media-box d-flex">
          <div style="width: 80%">
            <input
              @click.stop="showBar()"
              type="text"
              class="form-control search-bar"
              name=""
              id=""
              placeholder="Search ..."
              v-model="keywords"
              @keyup.enter="goto"
            />
            <div :class="[relationBar ? 'searchActive' : 'notActive']">
              <div
                @click="relationGoto(index)"
                v-for="(keywords, index) in keywordsLists"
                :key="index.name"
                :class="{ keywordsList: relationBar }"
                class="cursor-pointer"
              >
                {{ keywords.name }}
              </div>
              <div class="pb-3 popular-topic" style="text-align: left">
                Popular Topic
              </div>
              <div
                @click="topicGoto(index)"
                v-for="(rank, index) in rankTitleList"
                :key="index.keyWord"
                class="rankwordsList cursor-pointer"
              >
                #{{ rank.keyWord }}
              </div>
            </div>
          </div>
          <div class="line-outside">
            <div class="line"></div>
          </div>
          <div class="m-auto dropdown-div">
            <b-dropdown
              class="searchbar-dropdown"
              :text="dropDownText"
              no-caret
            >
              <template #button-content>
                <div class="d-flex align-items-center" @click="showMediaBar()">
                  {{ selectMediaName }}
                  <i class="fas fa-caret-down drop-icon"></i>
                </div>
              </template>
              <div class="justify-content-center type-panel">
                <div
                  v-for="(mediaList, index) in mediaLists"
                  :key="index.classerName"
                >
                  <b-form-group
                    :label="mediaList.classerName.toUpperCase()"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      stacked
                      buttons
                    >
                      <b-form-checkbox
                        v-for="(media, index) in mediaList.media"
                        :key="index.name"
                        :value="media.name"
                        >{{ media.name }}</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </b-dropdown>
          </div>
        </div>
        <div type="button" class="btn btn-primary search-btn" @click="goto">
          SEARCH
        </div>
      </div>
      <div class="white-space d-none d-md-flex"></div>
      <!-- 768尺寸以下header -->
      <div
        class="d-flex d-md-none align-items-center"
        :class="{ phoneBar: searchPhone }"
      >
        <router-link class="logo" :class="{ phoneLogo: searchPhone }" to="/">
          <div>LOGO</div>
        </router-link>
        <div class="search-box" :class="{ phonesearchbox: searchPhone }">
          <input
            @click.stop="showPhoneMode()"
            class="form-control search-bar"
            type="text"
            name=""
            id=""
            v-model="keywords"
            placeholder="Search ..."
          />
          <div :class="[searchPhone ? 'phoneActive' : 'notActive']">
            <!-- phone search上半部（關鍵字） -->
            <div style="height: 49vh; overflow: scroll">
              <div
                @click="relationGoto(index)"
                v-for="(keywordsList, index) in keywordsLists"
                :key="index.name"
                :class="{ keywordsList: searchPhone }"
                class="cursor-pointer"
              >
                {{ keywordsList.name }}
              </div>
              <div class="popular-topic" style="text-align: left">
                Popular Topic
              </div>
              <div
                @click="topicGoto(index)"
                v-for="(rank, index) in rankTitleList"
                :key="index.keyWord"
                class="keywordsList cursor-pointer"
              >
                #{{ rank.keyWord }}
              </div>
            </div>
            <!-- phone search上半部（關鍵字） -->
            <!-- phone search下半部（媒體） -->
            <div class="white-line"></div>
            <div>
              <div
                class="text-left mt-3"
                style="margin-left: 33px; font-size: 28px"
              >
                Select Media
              </div>
              <div class="justify-content-center type-panel">
                <div
                  v-for="(mediaList, index) in mediaLists"
                  :key="index.classerName"
                >
                  <b-form-group
                    :label="mediaList.classerName.toUpperCase()"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      stacked
                      buttons
                    >
                      <b-form-checkbox
                        v-for="(media, index) in mediaList.media"
                        :key="index.name"
                        :value="media.name"
                        >{{ media.name }}</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <button class="d-flex justify-content-end align-items-center btn search-btn" type="submit" @click="goto">
            <img :src="search" alt="">
            <!-- <b-icon icon="search" style="color: #ffffff"></b-icon> -->
          </button>
        </div>
        <div v-if="searchPhone == true" @click="closePhoneMode">
          <!-- <b-icon icon="x" font-scale="2" style="color: #ffffff"></b-icon> -->
          <img class="ml-2" :src="cross" alt="">
        </div>
      </div>
      <!-- 768尺寸以下header -->
    </div>
    <!-- 768尺寸以下dropdown -->
    <div
      v-if="$route.path == '/'"
      class="header-dropdown justify-content-center align-items-center"
    >
      <div
        class="
          header-outer-box
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="header-box d-flex align-items-center">
          <img :src="fire" class="ml-2" />
          <hotKey />
        </div>
        <!-- 社群.... -->
        <div>
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            class="dot-dropdown"
          >
            <template #button-content>
              <div class="dot-text">
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
              </div>
            </template>
            <b-dropdown-item href="https://t.me/blocktempo" target="_blank"
              >Telegram</b-dropdown-item
            >
            <b-dropdown-item
              href="https://twitter.com/BlockTempo?s=20"
              target="_blank"
              >Twitter</b-dropdown-item
            >
            <b-dropdown-item
              href="https://www.linkedin.com/company/blocktempo/"
              target="_blank"
              >LinkedIn</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <!-- 社群.... -->
      </div>
    </div>
    <!-- 768尺寸以下dropdown -->
  </div>
</template>
<script>
const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
import { getSelectMedia } from "@/axios/app.js";
import { getKeywordRelation } from "@/axios/app.js";
import hotKey from "./hotKey.vue";
import { getTitleRank, getTitleRankByTime } from "@/axios/app.js";
import * as DateTime from "@/utlis/DateTime";
export default {
  data() {
    return {
      selected: [],
      keywords: "",
      keywordsLists: [],
      keywordsList: "",
      rank: "",
      dropDownText: "Select Media",
      mediaLists: null,
      relationBar: false,
      selectMediaBar: false,
      relationLists: null,
      currentTab: "by Title",
      tabs: ["by Title", "by Content"],
      rankTitleList: null,
      searchPhone: false,
      selectMediaName: "Select Media",
      fire: require("@/assets/fire.svg"),
      search: require("@/assets/search.svg"),
      cross: require("@/assets/delet.svg"),
    };
  },
  components: {
    hotKey,
  },
  created() {
    getSelectMedia().then((r) => {
      this.mediaLists = r.data.data;
    });
  },
  mounted() {
    getTitleRank().then((r) => {
      this.rankTitleList = r.data.data;
    });
  },
  methods: {
    showPhoneMode() {
      this.searchPhone = !this.searchPhone;
      console.log(this.searchPhone);
    },
    closePhoneMode() {
      this.searchPhone = false;
    },
    showMediaBar() {
      let bar = this.$store.state.mediabar ? false : true;
      this.$store.commit("changeMediaBarState", bar);
      this.$store.commit("changeShowBarState", false);
    },
    showBar() {
      this.$store.commit("changeShowBarState", true);
    },
    fetchData() {
      getKeywordRelation(this.keywords).then((r) => {
        this.keywordsLists = r.data.data;
      });
    },
    changeTitleTime(obj) {
      let eTime = DateTime.toUnixTime(new Date().getTime());
      let sTime = DateTime.toUnixTime(
        new Date(DateTime.add(new Date(), -obj.value, obj.unit))
      );
      getTitleRankByTime(sTime, eTime).then((r) => {
        this.rankTitleList = r.data.data;
      });
    },
    relationGoto(index) {
      this.searchPhone = false;
      this.keywords = this.keywordsLists[index].name;
      this.$router.push(`/search/${this.keywords}`);
    },
    topicGoto(index) {
      this.searchPhone = false;
      this.rank = this.rankTitleList[index].id;
      this.$router.push(`/rank/${this.rank}`);
    },
    goto() {
      this.searchPhone = false;
      this.$store.commit("changeType", this.selected);
      this.$router.push({ path: `/search/${this.keywords}` });
    },
  },
  watch: {
    keywords() {
      delay(() => {
        this.fetchData();
      }, 300);
    },
    selected() {
      let selected = this.selected;
      let words = "";
      words = selected.join(",");
      this.selectMediaName = words;
      console.log(words.length);
      if (words.length > 10) {
        this.selectMediaName = words.substring(0, 10) + "...";
      } else if (words.length == 0) {
        this.selectMediaName = "Select Media";
      } else {
        return words;
      }
    },
    "$store.state.showbar"(val) {
      this.relationBar = val;
    },
    "$store.state.mediabar"(val) {
      this.selectMediaBar = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.phoneActive {
  position: absolute;
  width: 100%;
  top: 90px;
  right: 0px;
  height: 100vh;
  overflow: scroll;
  background: #000000;
  margin-top: 0.5rem;
  z-index: 999;
}
.white-line {
  width: 90%;
  border: solid 1px #ffffff;
  margin: auto;
}
.searchActive {
  position: absolute;
  width: 100%;
  height: 350px;
  overflow: scroll;
  background: linear-gradient(184.36deg, #3d3d3d 1.34%, #232323 86.78%);
  border-radius: 10px;
  margin-top: 0.5rem;
  z-index: 999;
}
.phonesearchbox {
  border: solid 1px #ffffff;
}
.notActive {
  display: none;
}
input::placeholder {
  color: #a8a9b0;
}

.white-space {
  width: 50px;
  height: 50px;
}

.background {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  background: linear-gradient(
    269.92deg,
    #373737 9.57%,
    rgba(48, 48, 48, 0) 17.03%,
    #141414 72.74%
  );
}

.logo {
  display: flex;
  align-items: center;
  padding: 0 40px 0 40px;
  margin: 0 120px 0 40px;
  height: 100%;
  font-family: Helvetica;
  font-size: 36px;
  line-height: 36px;
  background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}
.line-outside {
  display: flex;
  align-items: center;
  width: 3px;
  height: 48px;
  background: transparent;
}

.search-media-box {
  width: 100%;
  background: linear-gradient(
    269.96deg,
    rgba(225, 215, 255, 0.56) 13.55%,
    #343434 108.79%
  );
  border-radius: 10px 0px 0px 10px;
}

.line {
  width: 3px;
  height: 30px;
  border-right: solid white 1px;
  background: transparent;
}

.searchbar-dropdown {
  width: 120px;
  height: 48px;
  display: flex;
  align-items: center;
  background: transparent;
  ::v-deep .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    height: 18px;

    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    color: #dfdfdf;

    background: transparent;
    border-radius: 3px;
    border: none;
    padding: 2px 8px;
  }
  ::v-deep .drop-icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 20px;
  }

  ::v-deep .dropdown-menu.show {
    min-width: 19rem;
    left: -40px !important;
    margin: 1rem 0;
    border-radius: 10px;
    background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    overflow-y: auto;
    .dropdown-item {
      padding: 5px 12px;
      color: #dfdfdf;
      &:hover {
        background: rgb(49, 49, 49);
      }
    }
  }
  ::v-deep .btn-secondary.dropdown-toggle {
    background-color: transparent !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

// select media 部分css
.dropdown-header {
  display: block;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
}
.type-panel {
  display: grid;
  grid-template-columns: 120px 120px;
  max-height: 60vh;
  ::v-deep .col-form-label {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 0.5rem;
  }
  ::v-deep .btn.btn-secondary {
    font-size: 14px;
    margin-bottom: 5px;
    background-color: transparent;
    border-color: transparent;
    &:focus {
      box-shadow: none !important;
    }
  }
  ::v-deep .btn-secondary:not(:disabled):not(.disabled):active{
    color: #cdbbff;
    background: transparent;
  }
  ::v-deep .btn.btn-secondary.active {
    color: #cdbbff;
    background-color: transparent;
  }
  ::v-deep .btn.btn-secondary.focus {
    box-shadow: none !important;
  }
  ::v-deep .btn-secondary.dropdown-toggle {
    background-color: transparent !important;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
.popular-topic {
  color: #a9a6b6;
  font-family: "Inter";
  font-size: 12px;
  line-height: 28px;
  margin-left: 21px;
  margin-top: 40px;
}
.keywordsList {
  text-align: left;
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  margin-left: 22px;
}
.rankwordsList {
  text-align: left;
  font-family: "Tahoma";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-left: 22px;
}

.search-box {
  padding: 0;
  display: flex;
  align-items: center;
}

.search-btn {
  border: none;
  width: 120px;
  height: 48px;
  align-items: flex-start;
  padding: 12px 16px;
  background: linear-gradient(95.08deg, #b2f9d9 -131.38%, #9ca0dc 114.54%);
  border-radius: 0px 10px 10px 0px;
  margin: -4px;
  font-style: normal;
  font-weight: 900;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }
}

.search-bar {
  border: none;
  color: #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border-radius: 10px 0 0 10px;
  padding: 12px 8px 12px 24px;
  height: 48px;
  width: 100%;

  &:focus {
    box-shadow: none;
  }
}
.form-control:focus {
  background: transparent;
  color: #dfdfdf;
}
// 手機版hotkeyword 部分 css
.header-dropdown {
  display: none;
  background: linear-gradient(
    269.92deg,
    #373737 9.57%,
    rgba(48, 48, 48, 0) 17.03%,
    #141414 72.74%
  );
  text-align: left;
  padding-bottom: 1rem;
  padding-left: 2rem;
}
.header-outer-box {
  min-width: 680px;
}
.header-box {
  background: #2f2f2f;
  border-radius: 15px;
  height: 36px;
}
#header-dropdown {
  ::v-deep .dropdown-menu.show {
    top: 141px !important;
    left: -34px !important;
    border-radius: 15px;
    max-width: 282px;
    margin: 0;
    background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    .dropdown-item {
      padding: 5px 12px;
      color: #dfdfdf;
      &:hover {
        background: rgb(49, 49, 49);
      }
    }
  }
  ::v-deep .btn-secondary.dropdown-toggle {
    border-color: transparent;
    border-radius: 0px 15px 15px 0px;
    background: transparent;
    display: flex;
    align-items: center;
    &:focus {
      box-shadow: none !important;
    }
  }
}
.dot-dropdown {
  ::v-deep .dropdown-menu {
    position: relative;
    min-width: 126px;
  }
  ::v-deep .btn:focus {
    outline: 0;
    box-shadow: none;
  }
  ::v-deep .dropdown-menu.show {
    top: 0px;
    left: -30px !important;
    border-radius: 10px;
    margin: 0;
    background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

    .dropdown-item {
      padding: 5px 12px;
      color: #dfdfdf;
      &:hover {
        background: rgb(49, 49, 49);
      }
    }
  }
}
.dot-text {
  width: 40px;
  height: 40px;
  background: #5a5a5a;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dots {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 100%;
  margin: 0px 2px;
}
@media (min-width: 993px) and (max-width: 1440px) {
  .logo {
    margin: 0 40px 0 40px;
  }
  .searchbar-dropdown {
    width: 120px;
    height: 48px;
    display: flex;
    align-items: center;
    background: transparent;
    ::v-deep .dropdown-menu.show {
      min-width: 10rem;
      left: -10px !important;
    }
  }
}
@media (max-width: 992px) {
  .logo {
    margin: 0 60px 0 20px;
  }
  .searchbar-dropdown {
    width: 120px;
    height: 48px;
    display: flex;
    align-items: center;
    background: transparent;
    ::v-deep .dropdown-menu.show {
      min-width: 7rem;
      left: -5px !important;
    }
  }
}
@media (max-width: 768px) {
  .header-dropdown {
    display: flex;
  }
}
@media (max-width: 767px) {
  .background {
    justify-content: space-around;
  }
  .phoneheader {
    background: #000000;
    display: flex;
    align-items: center;
  }
  .logo {
    padding: unset;
    margin: 0 30px 0 20px;
  }
  .phoneLogo {
    display: none;
  }
  .phoneBar {
    margin-left: 8rem;
  }
  .search-box {
    min-width: 389px;
    background: linear-gradient(
      269.96deg,
      rgba(225, 215, 255, 0.56) 13.55%,
      #343434 108.79%
    );
    border-radius: 10px;
  }
  .search-btn {
    background: transparent;
    &:focus {
      box-shadow: none !important;
    }

    &:active {
      box-shadow: none !important;
    }
  }
  .search-bar {
    background: transparent;
    border-radius: 10px;
    &:focus {
      box-shadow: none;
    }
  }
  .header-outer-box {
    min-width: 543px;
  }
  .popular-topic {
    color: #a9a6b6;
    font-size: 14px;
    line-height: 28px;
    margin-left: 35px;
    margin-bottom: 5px;
  }
  .keywordsList {
    font-weight: 400;
    font-size: 24px;
    line-height: 43px;
    margin-left: 35px;
  }
  .rankwordsList {
    text-align: left;
    font-family: "Tahoma";
    font-weight: 400;
    font-size: 24px;
    line-height: 43px;
    margin-left: 35px;
  }
  .type-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 60vh;
    text-align: left;
    margin-left: 22px;
    margin-top: 1rem;
    ::v-deep .col-form-label {
      font-family: "Inter";
      font-size: 24px;
      line-height: 31px;
      font-weight: 700;
      color: white;
      padding: 0.5rem;
    }
    ::v-deep .btn.btn-secondary {
      text-align: left;
      font-size: 24px;
      margin-bottom: unset;
      padding: 2px 12px;
      right: 2px;
    }
    ::v-deep .btn-secondary:not(:disabled):not(.disabled).active {
      border-color: transparent;
    }
  }
}
@media (max-width: 575px) {
  .logo {
    margin: 0 20px 0 20px;
  }
  .search-box {
    min-width: 220px;
    background: linear-gradient(
      269.96deg,
      rgba(225, 215, 255, 0.56) 13.55%,
      #343434 108.79%
    );
    border-radius: 10px;
    margin-right: 10px;
  }
  .header-outer-box {
    min-width: 485px;
  }
}
@media (max-width: 496px) {
  .header-dropdown {
    padding-left: 1rem;
  }
  .search-box {
    min-width: unset;
    max-width: 210px;
    background: linear-gradient(
      269.96deg,
      rgba(225, 215, 255, 0.56) 13.55%,
      #343434 108.79%
    );
    border-radius: 10px;
    margin-right: 10px;
  }
  .header-outer-box {
    min-width: 350px;
  }
}
</style>
