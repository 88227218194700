<template>
  <div>
    <div class="cardTop-div">
      <div class="little-box"></div>
    </div>
    <div>
      <div :class="arrow ? 'card-show' : ''" class="card1">
        <div class="card1-title-box">
          <div v-if="type == 'Title'" class="card1-title">Sort by Title</div>
          <div v-else-if="type == 'Content'" class="card1-title">
            Sort by Content
          </div>

          <div class="dropdown-div">
            <b-dropdown
              id="dropdown-1"
              :text="dropDownText"
              class="Dropdown dropdown-menu1 Button"
              no-caret
            >
              <template #button-content>
                {{ dropDownText }}
                <i class="fas fa-caret-down drop-icon"></i>
              </template>
              <b-dropdown-item-button
                @click="changeTime(24, 'h')"
                class="cursor-pointer"
              >
                24H
              </b-dropdown-item-button>
              <b-dropdown-item-button
                @click="changeTime(7, 'd')"
                class="cursor-pointer"
              >
                7D
              </b-dropdown-item-button>
              <b-dropdown-item-button
                class="cursor-pointer"
                @click="changeTime(1, 'M')"
                >1M
              </b-dropdown-item-button>
              <b-dropdown-item-button
                @click="changeTime(2, 'M')"
                class="cursor-pointer"
                >2M
              </b-dropdown-item-button>
              <b-dropdown-item-button
                @click="changeTime(3, 'M')"
                class="cursor-pointer"
              >
                3M
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
        <div :class="loadingShow ? 'show' : ''" class="loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>

        <div>
          <router-link
            :to="`/rank/${rank.id}`"
            v-for="(rank, index) in rankList"
            :key="index"
            class="ranking-box"
          >
            <div v-if="index == 0" class="sort-number color-no1">
              <img :src="crownPurple" alt="">
            </div>
            <div v-else-if="index == 1" class="sort-number color-no2">
              <img :src="crownBlue" alt="">
            </div>
            <div v-else-if="index == 2" class="sort-number color-no3">
              <span class="color-no3"><img :src="crownGreen" alt=""></span>
            </div>
            <div v-else class="sort-number">{{ changeIndex(index) }}</div>

            <div v-if="index == 0" class="ranking-keyword color-no1">
              {{ rank.keyWord }}
            </div>
            <div v-else-if="index == 1" class="ranking-keyword color-no2">
              {{ rank.keyWord }}
            </div>
            <div v-else-if="index == 2" class="ranking-keyword color-no3">
              {{ rank.keyWord }}
            </div>
            <div v-else class="ranking-keyword">
              {{ rank.keyWord }}
            </div>

            <div class="ranking-number">{{ rank.mention }}</div>
          </router-link>
        </div>
      </div>
      <div
        @click="arrowChange()"
        :class="arrow ? 'down' : 'up'"
        class="arrow cursor-pointer"
      >
        <i :class="arrow ? 'fa-caret-up' : 'fa-caret-down'" class="fas"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { BDropdown } from "bootstrap-vue";

export default {
  components: {
    BDropdown,
  },
  data() {
    return {
      arrow: false,
      dropDownText: "24H",
      loadingShow: "false",
      crownPurple: require("@/assets/purple.svg"),
      crownBlue: require("@/assets/blue.svg"),
      crownGreen: require("@/assets/green.svg"),
    };
  },
  props: {
    type: String,
    rankList: Array,
  },
  watch: {
    rankList: function () {
      this.loadingShow = false;
    },
  },
  methods: {
    changeIndex(val) {
      val += 1;
      if (val >= 10) {
        return val;
      } else {
        return "0" + val;
      }
    },
    changeTime(val, unit) {
      this.loadingShow = true;
      // this.arrow = false;
      var dict = { h: "H", d: "D", M: "M" };
      var dropDownText = val + dict[unit];
      this.dropDownText = dropDownText;

      var obj = {
        value: val,
        unit: unit,
      };
      if (this.type == "Title") {
        this.$emit("changeTitleTime", obj);
      } else if (this.type == "Content") {
        this.$emit("changeContentTime", obj);
      }
    },
    arrowChange() {
      this.arrow = !this.arrow;
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Oxanium&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxanium&family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

a {
  text-decoration: none !important;
}

.loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 228px;
  top: 58px;
  height: 408px;
  background: linear-gradient(
    180deg,
    rgba(156, 156, 156, 0) 0.52%,
    #333239 100%
  );
  background-blend-mode: overlay;
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 15px 15px;
  z-index: -1;
  i {
    position: relative;
    top: 5px;
    font-size: 30px;
  }
}

.loading.show {
  z-index: 1;
}

ul {
  list-style-type: none;
  padding-left: 0px;
  li {
    text-align: left;
  }
}

.ranking-box {
  display: flex;
  padding-bottom: 10px;
  margin: 12px 19px 13px 19px;
  align-items: center;
  border-bottom: 0.5px solid #777777;
}

.ranking-keyword {
  display: flex;
  align-items: center;
  height: 12px;
  color: #dfdfdf;
  margin-left: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 12px;
}

.ranking-number {
  width: 18px;
  height: 12px;
  margin-left: auto;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #dfdfdf;
}

.little-box {
  position: relative;
  border-radius: 50px;
  top: 6px;
  left: 7px;
  height: 6px;
  width: 42px;
  background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
  box-shadow: 1px 0px 3px 1px #adf1e480;
}

.cardTop-div {
  border-radius: 15px 15px 0 0;
  width: 188px;
  height: 15px;
  margin-top: 20px;
  margin-left: 30px;
  background: linear-gradient(-145deg, transparent 80px, #575757 0) left;
}

.card1 {
  overflow: hidden;
  position: relative;
  width: 225px;
  height: 445px;
  margin-left: 30px;
  background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
  box-shadow: 10px 10px 10px rgba(37, 36, 44, 0.4);
  border-radius: 0px 15px 15px 15px;
}
.card1-title-box {
  display: flex;
  align-items: center;
  margin: 20px 19px;
  padding-bottom: 15px;
  border-bottom: 2px solid #696b73;
  .card1-title {
    margin-right: auto;
    text-align: left;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    color: #dfdfdf;
    text-shadow: 0px 0px 3px rgba(190, 193, 255, 0.4);
  }
}

.sort-number {
  color: #dfdfdf;

  letter-spacing: 2px;
  font-size: 12px;
  font-family: "Inter", cursive;
  font-weight: 600;
  margin-right: 11px;
}

.dropdown-div {
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
}

.dropdown-menu1 {
  width: 60px;
  height: 18px;
  display: flex;
  align-items: center;
  background: #868686;
  border-radius: 3px;
  ::v-deep .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    height: 18px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    color: #dfdfdf;

    background: #2f2f2f;
    border-radius: 3px;
    padding: 2px 8px;
  }
  ::v-deep .drop-icon {
    background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 12px;
  }

  ::v-deep .dropdown-menu {
    min-width: 60px;
    margin: 0;
    background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    .dropdown-item {
      padding: 5px 12px;
      color: #dfdfdf;
      &:hover {
        background: rgb(49, 49, 49);
      }
    }
  }
}

.arrow {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  height: 47px;
  width: 225px;
  margin: 0 36px;
  right: 6px;
  top: -47px;

  background: linear-gradient(
    180deg,
    rgb(90 90 90 / 0%) 0.52%,
    rgb(42 42 42) 100%
  );
  background-blend-mode: overlay;
  backdrop-filter: blur(2px);
  border-radius: 0px 0px 15px 15px;

  i {
    padding-bottom: 8px;
    width: 30px;
    border-bottom: 3px solid #777777;
    border-radius: 2px;
    color: #777777;
    font-size: 20px;
  }
}

.arrow.down {
  background: none;
  backdrop-filter: none;
}

.card-show {
  height: 725px;
}

::v-deep .btn-secondary.dropdown-toggle {
  background-color: #2f2f2f !important;
}

.color-no1 {
  color: #9da4dc;
  img{
    
  }
}

.color-no2 {
  color: #a4c6d8;
}

.color-no3 {
  color: #a7d4d6;
}
</style>
