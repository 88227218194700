<template>
  <div class="sidebar-div">
    <div class="keyWord-box">
      <div class="d-flex align-items-center">
        <!-- <div class="dot"></div> -->
        <div class="keyWord">KEYWORD RANKING</div>
        <!-- <div class="dot"></div> -->
      </div>
    </div>

    <sidebar-card
      v-on:changeTitleTime="changeTitleTime"
      :rankList="rankTitleList"
      :type="'Title'"
    ></sidebar-card>
    <sidebar-card
      v-on:changeContentTime="changeContentTime"
      :rankList="rankContentList"
      :type="'Content'"
    ></sidebar-card>
    <div class="px-2 side-logo">
      <div class="text-left side-logo-title">LOGO</div>
      <div class="text-left side-logo-content">Webname is a website which collect many news media, you can know more latest news here.</div>
    </div>
  </div>
</template>
<script>
import {
  getTitleRank,
  getcontentRank,
  getTitleRankByTime,
  getContentRankByTime,
} from "@/axios/app.js";
import sidebarCard from "./sidebarCard.vue";
import * as DateTime from "@/utlis/DateTime";

export default {
  data() {
    return {
      rankTitleList: null,
      rankContentList: null,
    };
  },
  components: {
    sidebarCard,
  },
  mounted() {
    getTitleRank().then((r) => {
      this.rankTitleList = r.data.data;
    });

    getcontentRank().then((r) => {
      this.rankContentList = r.data.data;
    });
  },
  methods: {
    changeTitleTime(obj) {
      let eTime = DateTime.toUnixTime(new Date().getTime());
      let sTime = DateTime.toUnixTime(
        new Date(DateTime.add(new Date(), -obj.value, obj.unit))
      );
      getTitleRankByTime(sTime, eTime).then((r) => {
        this.rankTitleList = r.data.data;
      });
    },
    changeContentTime(obj) {
      let eTime = DateTime.toUnixTime(new Date().getTime());
      let sTime = DateTime.toUnixTime(
        new Date(DateTime.add(new Date(), -obj.value, obj.unit))
      );
      getContentRankByTime(sTime, eTime).then((r) => {
        this.rankContentList = r.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.keyWord-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 30px 30px 30px;
  width: 225px;
  .keyWord {
    margin: 0 12px;
    background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  // .dot {
  //   width: 7px;
  //   height: 7px;
  //   border-radius: 500px;
  //   background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
  // }
}

.sidebar-div {
  position: relative;
  width: 280px;
  min-height: 100vh;
  background: linear-gradient(180deg, #373737 21.86%, #2A2929 78.71%);
}

.little-box {
  position: relative;
  border-radius: 50px;
  top: 8px;
  left: 10px;
  height: 6px;
  width: 42px;
  background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
}

.side-logo{
  margin-left: 25px;
  position: absolute;
  bottom: 96px;
}

.side-logo-title{
  font-family: "Inter";
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 24px;
}
.side-logo-content{
  font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.012em;
text-align: left;

}
@media (max-width: 768px) {
  .sidebar-div {
    display: none;
}

}
</style>
