<template>
  <div class="footer-wrapper">
    <div class="footer d-none d-md-flex">
      <div class="icon-box d-none d-md-flex">
        <a
          href="https://t.me/blocktempo"
          target="_blank"
          rel="noreferrer noopener"
          class="icon cursor-pointer"
        >
          <i class="fab fa-telegram-plane"></i>
        </a>
        <a
          href="https://twitter.com/BlockTempo?s=20"
          target="_blank"
          rel="noreferrer noopener"
          class="icon cursor-pointer"
        >
          <i class="fab fa-twitter"></i
        ></a>
        <a
          href="https://www.linkedin.com/company/blocktempo/"
          target="_blank"
          rel="noreferrer noopener"
          class="icon cursor-pointer"
        >
          <i class="fab fa-linkedin-in"></i>
        </a>
      </div>
      <div class="email-box d-none d-md-flex">
        <div class="email-content-box">
          <div class="email-content">E-mail notifications</div>
          <div class="email-content2">
            <div class="join-text">Join our subscribers to</div>
            <div>get the latest news and special offers.</div>
          </div>
        </div>

        <div class="email-area">
          <div class="email-submit-box">
            <input
              class="email-input"
              type="text"
              placeholder="Your E-mail Address"
            />
            <div
              @click="openModal()"
              class="submit-button"
              type="button"
              value="Subscribe"
            >
              Subscribe
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trademark d-none d-md-flex justify-content-end">
      © 2021 CryptoXlab. All Rights Reserved
    </div>
    <!-- 786尺寸以下footer -->
    <div class="footer-sm d-md-none">
      <div class="email-box">
        <div class="email-content-box">
          <div class="email-content">E-mail notifications</div>
          <div class="email-content2">
            <div class="join-text">
              Join our subscribers to get the latest news and special offers.
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="width: 100%">
          <div class="email-submit-box">
            <input
              class="email-input"
              type="text"
              placeholder="Your E-mail Address"
            />
            <div
              @click="openModal()"
              class="submit-button"
              type="button"
              value="Subscribe"
            >
              Subscribe
            </div>
          </div>
        </div>
      </div>
      <div class="icon-box justify-content-center">
        <a
          href="https://t.me/blocktempo"
          target="_blank"
          rel="noreferrer noopener"
          class="icon cursor-pointer"
        >
          <i class="fab fa-telegram-plane"></i>
        </a>
        <a
          href="https://twitter.com/BlockTempo?s=20"
          target="_blank"
          rel="noreferrer noopener"
          class="icon cursor-pointer"
        >
          <i class="fab fa-twitter"></i
        ></a>
        <a
          href="https://www.linkedin.com/company/blocktempo/"
          target="_blank"
          rel="noreferrer noopener"
          class="icon cursor-pointer"
        >
          <i class="fab fa-linkedin-in"></i>
        </a>
      </div>
      <div class="trademark">© 2021 CryptoXlab. All Rights Reserved</div>
    </div>
    <b-modal
      content-class="content"
      centered
      hide-header
      hide-footer
      id="modal"
    >
      <div>
        <i
          @click="closeModal()"
          class="float-right fas fa-times cursor-pointer"
        ></i>
      </div>
      <div class="py-5 content-text">Thank you for your subscribe.</div>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    openModal() {
      this.$bvModal.show("modal");
    },
    closeModal() {
      this.$bvModal.hide("modal");
    },
  },
};
</script>

<style lang="scss" scoped>
input::placeholder {
  color: #dfdedf;
}

a:hover {
  text-decoration: none;
}

.footer-wrapper {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
}
.footer {
  display: flex;

  justify-content: space-between;
  padding-top: 40px;
  margin-top: 50px;
  padding-bottom: 40px;

  border-top: 0.5px solid #7c7c84;
  border-bottom: 0.5px solid #7c7c84;
}

.icon-box {
  display: flex;
  align-items: center;
  width: 30%;
  .icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
    width: 40px;
    height: 40px;
    font-size: 28px;
    border-radius: 500px;
    background-color: #828282;
    color: #141414;
    &:hover {
      background-color: #dfdfdf;
    }
  }
}

.email-box {
  display: flex;
  align-items: center;
  width: 70%;
  height: 100%;
}

.email-content-box {
  width: 238px;
  padding-left: 30px;
  border-left: 0.5px solid #7c7c84;
  .email-content {
    width: 100%;
    margin-bottom: 18px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #dfdfdf;
  }

  .email-content2 {
    width: 100%;
    text-align: left;

    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #dfdfdf;

    .join-text {
      margin-bottom: 3px;
    }
  }
}

.email-area {
  width: calc(100% - 238px);
}
.email-submit-box {
  float: right;
  width: 95%;
}

.email-input {
  padding-left: 18px;
  width: 100%;
  height: 37px;
  background: #444444;
  border-radius: 6px;
  color: #dfdfdf;
  border: none;
  &:focus-visible {
    outline: none;
  }
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin-top: 10px;
  width: 100%;
  height: 23px;
  background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
  border-radius: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}

.trademark {
  margin: 7px 0 45px 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 17px;
}

::v-deep .content {
  background: rgba(83, 83, 83, 0.9);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
  color: #dfdfdf;

  border-radius: 15px;

  .content-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 992px) and (max-width: 1439px) {
  .footer-wrapper {
    padding: 0 25px;
  }
  .footer {
    justify-content: space-between;
  }
  .icon-box {
    width: 180px;
  }

  .email-box {
    display: flex;
    align-items: center;
    width: calc(100% - 180px);
    height: 100%;
  }

  .email-content-box {
    width: 230px;
    padding-left: 10px;
    border-left: 0.5px solid #7c7c84;
    .email-content {
      width: 230px;
      margin-bottom: 18px;
      font-family: 'Inter';
      font-size: 18px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #dfdfdf;
    }

    .email-content2 {
      width: 230px;
    }
  }
  .email-area {
    width: 100%;
  }
  .email-submit-box {
    float: right;
    width: 100%;
  }

  .email-input {
    padding-left: 18px;
    width: 100%;
    height: 37px;
    background: #444444;
    border-radius: 6px;
    color: #dfdfdf;
    border: none;
    &:focus-visible {
      outline: none;
    }
  }

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    margin-top: 10px;
    width: 100%;
    height: 23px;
    background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
    border-radius: 6px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
.footer-wrapper {
    padding: 0 25px;
  }
  .footer {
    justify-content: space-between;
  }
  .icon-box {
    width: 170px;
    .icon {
    margin: 5px;
    width: 30px;
    height: 30px;
  }
  }

  .email-box {
    display: flex;
    align-items: center;
    width: calc(100% - 160px);
    height: 100%;
  }

  .email-content-box {
    width: 230px;
    padding-left: 10px;
    border-left: 0.5px solid #7c7c84;
    .email-content {
      width: 230px;
      margin-bottom: 18px;
      font-family: 'Inter';
      font-size: 18px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #dfdfdf;
    }

    .email-content2 {
      width: 230px;
    }
  }
  .email-area {
    width: 100%;
  }
  .email-submit-box {
    float: right;
    width: 100%;
  }

  .email-input {
    padding-left: 18px;
    width: 100%;
    height: 37px;
    background: #444444;
    border-radius: 6px;
    color: #dfdfdf;
    border: none;
    &:focus-visible {
      outline: none;
    }
  }

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    margin-top: 10px;
    width: 100%;
    height: 23px;
    background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
    border-radius: 6px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
  }
}
@media (max-width: 767px) {
  .email-box {
    display: unset;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .email-content-box {
    width: 100%;
    padding-left: 30px;
    border-left: unset;
    margin-bottom: 90px;
    .email-content {
      width: 100%;
      text-align: center;
    }

    .email-content2 {
      text-align: center;
      .join-text {
        margin-bottom: 3px;
      }
    }
  }
  .email-submit-box {
    float: unset;
    width: 412px;
  }

  .email-input {
    padding-left: 18px;
    width: 100%;
    height: 37px;
    background: #444444;
    border-radius: 6px;
    color: #dfdfdf;
    border: none;
    &:focus-visible {
      outline: none;
    }
  }

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    margin-top: 10px;
    width: 100%;
    height: 23px;
    background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
    border-radius: 6px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
  }
  .icon-box {
    width: 100%;
  }
  .trademark {
    text-align: center;
  }
}
</style>
