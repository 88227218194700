<template>
  <div id="app" @click="hideBlock">
    <myheader></myheader>
    <div class="d-flex" :class="{ active: showBar }">
      <sidebar></sidebar>
      <div class="mybody">
        <router-view />
        <myfooter></myfooter>
      </div>
    </div>
  </div>
</template>
<script>
import myheader from "@/components/myheader";
import sidebar from "@/components/sidebar";
import myfooter from "@/components/footer";

export default {
  components: {
    myfooter,
    myheader,
    sidebar,
  },
  data (){
    return{
      showBar: false,
      phoneMode: false
    }
  },
  methods:{
    hideBlock(){
      this.showBar = false;
      this.$store.commit("changeShowBarState", false)
      this.$store.commit("changeMediaBarState", false)
    }
  },
  watch:{
    "$store.state.showbar"(val){
      let mediabar = this.$store.state.mediabar
      if(mediabar || val){
        this.showBar = true;
      }else this.showBar = false;
    },
     "$store.state.mediabar"(val){
      let bar = this.$store.state.showbar;
      if(bar || val){
        this.showBar = true;
      }else this.showBar = false;
    },
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.active {
background: #000000;
-webkit-filter:brightness(.1);
z-index: -1;
}

#app {
  font-family: 'Inter',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #dfdfdf;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    #373737 21.86%,
    #303030 27.88%,
    #141414 78.71%
  );
}

.mybody {
  width: calc(100% - 280px);
  display: flex;
  flex-direction: column;

  background: #303030;
  }

.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 768px) {
  .mybody {
  width: 100%;
}
}
</style>
